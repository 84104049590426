import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
//import Slider from "react-slick"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import iconCorporateHammer from "../../static/assets/icon/anti-corruption-policy.png"
import iconCorporateNote from "../../static/assets/icon/business-ethics-policy.png"
import iconCorporateHealth from "../../static/assets/icon/health-policy.png"
import iconCorporateTree from "../../static/assets/icon/environmental-policy.png"
import iconCorporateCheck from "../../static/assets/icon/receive-a-complaint.png"
import iconSustainability from "../../static/assets/icon/sustainability.png"
import { Link } from "gatsby-plugin-react-intl"

import { Row, Col } from "antd"
import styled from "@emotion/styled"

/*const ImageContentHalf = styled.div`
  margin-bottom: 80px;
  &.imgLeft,
  &.imgRight {
    position: relative;
    .imageWrapper {
      width: 100%;
      max-width: 100%;
      height: auto;
      overflow: hidden;
    }
  }
  .smallTitle {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #181818;
    margin-top: 80px;
  }
  .contentWrapper {
    font-family: "Kanit", sans-serif;
    .sub {
      display: inline-block;
      margin-top: 80px;
      font-weight: 300;
      font-size: 14px;
      color: #4a789c;
    }
    .title {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
    ul.list {
      list-style: none;
      li {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        .hilight {
          color: #7497b2;
        }
      }
    }
  }
  .textWrapper {
    font-family: "Kanit", sans-serif;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 100px;
    &.imgLeft {
      .imageWrapper {
        max-width: 45%;
        max-height: 50vh;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.imgRight {
      .imageWrapper {
        max-width: 45%;
        max-height: 50vh;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .smallTitle {
      margin-top: 0;
    }
    .contentWrapper {
      min-height: 50vh;
      .sub {
        margin-top: 0;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    &.imgRight,
    &.imgLeft {
      .imageWrapper {
        max-width: 42%;
        max-height: 55vh;
      }
    }
    .contentWrapper {
      min-height: 55vh;
    }
  }
`
const FullwidthContainer = styled.div`
  margin-bottom: 60px;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    color: #181818;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
    .gb-container {
      padding-right: 15%;
    }
  }
`*

const GalleryContainer = styled.div`
  margin: 50px 0;
  .slick-slider {
    padding-bottom: 50px;
    .slick-slide {
      padding: 0 10px;
    }
  }
  .slick-dots {
    bottom: 0;
    li {
      width: 12px;
      height: 12px;
      background: #ebebeb;
      border-radius: 100%;
      button {
        width: 12px;
        height: 12px;
        padding: 0;
        border-radius: 100%;
        border: 2px solid #ebebeb;
        &:before {
          width: 12px;
          height: 12px;
          color: #ebebeb;
          background: #ebebeb;
          border-radius: 100%;
        }
      }
      &:hover {
        button {
          border: 2px solid #fff;
        }
      }
      &.slick-active {
        button {
          border: 2px solid #7497b2;
          &:before {
            color: #fff;
            background: #fff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    margin: 70px 0;
    .slick-slider {
      padding-bottom: 70px;
    }
    .slick-dots {
      li {
        width: 15px;
        height: 15px;
        button {
          width: 15px;
          height: 15px;
          border: 3px solid #ebebeb;
          &:before {
            width: 15px;
            height: 15px;
          }
        }
        &:hover {
          button {
            border: 3px solid #fff;
          }
        }
        &.slick-active {
          button {
            border: 3px solid #7497b2;
          }
        }
      }
    }
  }
`
const ThreeColumnsContainer = styled.div`
  margin: 80px 0;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Kanit", sans-serif;
    .icon {
      display: inline-block;
      width: 64px;
      height: 64px;
      img {
        min-width: 64px;
        width: 64px;
        min-height: 64px;
        height: 64px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      color: #181818;
      margin-top: 25px;
      margin-bottom: 10px;
      text-align: center;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      margin-bottom: 0;
      text-align: center;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 100px 0;
  }
`
const HalfColumnsContainer = styled.div`
  margin-bottom: 60px;
  font-family: "Kanit", sans-serif;
  .title {
    font-weight: 400;
    font-size: 24px;
    color: #181818;
  }
  .contentWrapper {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
    ul.list {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        margin-bottom: 10px;
        .icon {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          margin-right: 10px;
          background-color: transparent;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("../../assets/images/icon-check-circle.svg");
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
  }
`*/

/* Top Design */
const FullwidthContainerheader = styled.div`
  margin-bottom: 60px;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #7497B2;
    margin-bottom: 0.5em;
  }
  .title {
    font-weight: 400;
    font-size: 36px;
    color: #181818;
    margin-bottom: 0.1em;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      padding: 0px 15px;
    }
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
    .gb-container {
      padding-right: 15%;
    }
    p {
      font-size: 18px;
    }
    .title {
      font-size: 38px;
      margin-bottom: 0.3em;
    }
    .content {
      p {
        padding: 0px 23%;
        font-size: 18px;
      }
    }
  }
`

const ThreeColumnsContainerbuttom = styled.div`
  margin: 50px 0;
  .item {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-family: "Kanit", sans-serif;
    padding: 50px 30px 50px 30px;
    border: 2px solid #7497b2;
    box-sizing: border-box;
    border-radius: 12px;
    .icon {
      display: inline-block;
      width: 84px;
      height: 84px;
    }
    .title {
      font-weight: 500;
      font-size: 22px;
      color: #2b3940;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: left;
    }
    &:hover {
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 50px 0;
  }
`

const CorporateGovernancePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    section1Content,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu2" })}
      />
      <FullwidthContainerheader
        style={{
          marginTop: "100px",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <Row>
          <Col span={24}>
            <p>{intl.formatMessage({ id: "siteTitle" })}</p>
            <h1 className="title">{intl.formatMessage({ id: "rootMenu2" })}</h1>
            <div className="content">
              <p>{section1Content.section1Content}</p>
            </div>
          </Col>
        </Row>
      </FullwidthContainerheader>
      <ThreeColumnsContainerbuttom
        style={{ marginTop: "50px", marginBottom: "100px" }}
      >
        <div className="gb-container">
          <Row gutter={[10, 10]}>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/anti-corruption-policy/">
                  <span className="icon">
                    <img
                      src={iconCorporateHammer}
                      width="83.13"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu24" })}
                  </p>
                </Link>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/business-ethics-policy/">
                  <span className="icon">
                    <img
                      src={iconCorporateNote}
                      width="73.98"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu21" })}
                  </p>
                </Link>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/health-policy/">
                  <span className="icon">
                    <img
                      src={iconCorporateHealth}
                      width="82"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu28" })}
                  </p>
                </Link>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/environmental-policy/">
                  <span className="icon">
                    <img
                      src={iconCorporateTree}
                      width="95.04"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu29" })}
                  </p>
                </Link>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/receive-a-complaint/">
                  <span className="icon">
                    <img
                      src={iconCorporateCheck}
                      width="53.13"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu23" })}
                  </p>
                </Link>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="item">
                <Link to="/corporate-governance/esg-policy/">
                  <span className="icon">
                    <img
                      src={iconSustainability}
                      width="80"
                      height="80"
                      alt=""
                    />
                  </span>
                  <p className="title">
                    {intl.formatMessage({ id: "subMenu30" })}
                  </p>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </ThreeColumnsContainerbuttom>
      {/*
      <ImageContentHalf
        id="section__1__object"
        className="imgRight"
        style={{ marginTop: "100px" }}
      >
        <div className="imageWrapper">
          <GatsbyImage image={getSection1Image} alt="" />
        </div>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12} xl={13} xxl={14}>
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: section1Content.section1Content,
                }}
              />
            </Col>
            <Col xs={24} lg={12} xl={11} xxl={10}></Col>
            <Col span={24}>
              <div
                className="textWrapper"
                dangerouslySetInnerHTML={{
                  __html: section1Text.section1Text,
                }}
              />
            </Col>
          </Row>
        </div>
      </ImageContentHalf>
      <ImageContentHalf id="section__2__object" className="imgLeft">
        <div className="imageWrapper">
          <GatsbyImage image={getSection2Image} alt="" />
        </div>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12} xl={11} xxl={10}></Col>
            <Col xs={24} lg={12} xl={13} xxl={14}>
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: section2Content.section2Content,
                }}
              />
            </Col>
          </Row>
        </div>
      </ImageContentHalf>
      <FullwidthContainer id="section__3__object">
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h4
                className="title"
                dangerouslySetInnerHTML={{
                  __html: section3Title,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: section3Content.section3Content,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      <ThreeColumnsContainer>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} md={12} lg={8}>
              <div className="item">
                <span className="icon">
                  <img src={iconHeart} width="48" height="48" alt="" />
                </span>
                <p
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: section3Process1Title,
                  }}
                />
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: section3Process1Text,
                  }}
                />
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div className="item">
                <span className="icon">
                  <img src={iconHeart} width="48" height="48" alt="" />
                </span>
                <p
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: section3Process2Title,
                  }}
                />
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: section3Process2Text,
                  }}
                />
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div className="item">
                <span className="icon">
                  <img src={iconHeart} width="48" height="48" alt="" />
                </span>
                <p
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: section3Process3Title,
                  }}
                />
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: section3Process3Text,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </ThreeColumnsContainer>
      <FullwidthContainer id="section__4__object">
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h4
                className="title"
                dangerouslySetInnerHTML={{
                  __html: section4Title,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: section4Content.section4Content,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      <FullwidthContainer id="section__5__object">
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h4
                className="title"
                dangerouslySetInnerHTML={{
                  __html: section5Title,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: section5Content.section5Content,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      <GalleryContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <div id="about__slider">
                <Slider {...sliderSettings}>
                  {gallery &&
                    gallery.map((slide, index) => {
                      const getSlideImage = getImage(slide)
                      return (
                        <div key={index} className="slide">
                          <GatsbyImage
                            image={getSlideImage}
                            alt={`About gallery ${index}`}
                          />
                        </div>
                      )
                    })}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </GalleryContainer>
      <HalfColumnsContainer id="section__6__object">
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12} xl={13} xxl={14}>
              <h4
                className="title"
                dangerouslySetInnerHTML={{
                  __html: section6Title,
                }}
              />
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: section6LeftContent.section6LeftContent,
                }}
              />
            </Col>
            <Col xs={24} lg={12} xl={11} xxl={10}>
              <h4
                className="title gb--visible--lg"
                dangerouslySetInnerHTML={{
                  __html: `&nbsp;`,
                }}
              />
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: section6RightContent.section6RightContent,
                }}
              />
            </Col>
          </Row>
        </div>
      </HalfColumnsContainer>
      <ImageContentHalf id="section__7__object" className="imgRight">
        <div className="imageWrapper">
          <GatsbyImage image={getSection7Image} alt="" />
        </div>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12} xl={13} xxl={14}>
              <h4
                className="smallTitle"
                dangerouslySetInnerHTML={{
                  __html: section7Title,
                }}
              />
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: section7Content.section7Content,
                }}
              />
            </Col>
            <Col xs={24} lg={12} xl={11} xxl={10}></Col>
          </Row>
        </div>
      </ImageContentHalf>
*/}
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query CorporateGovernancePageQuery($locale: String) {
    pageFields: allContentfulCorporateGovernancePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          section1Content {
            section1Content
          }
        }
      }
    }
    aboutFields: allContentfulAboutPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          gallery {
            gatsbyImageData(width: 750, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default injectIntl(CorporateGovernancePage)
